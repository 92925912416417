<template>
	<generic-data-editor
			title="Payment Methods"
			collection-name="payment-methods"
	/>
</template>

<script>

import GenericDataEditor from '@/components/generic-data-editor';

export default {

    name      : 'PaymentMethods',
    components: { GenericDataEditor }

};

</script>